.container_footer{
    padding: 2rem 6rem;
    /* margin: 3rem 5rem !important; */
}


@media (min-width: 700px) and (max-width: 900px) {
    .container_footer{
        padding: 2rem 10rem;
    }
}

@media (min-width: 910px)  {
    .container_footer{
        padding: 2rem 5rem;
    }
}

@media (max-width: 576px) {
    .container_footer{
        padding: 2rem 0rem;
    }
}

/*
@media (max-width: 576px) {
    .container_footer{
        margin: 2rem 5rem !important;
    }
}


@media (max-width: 1280px) {
    .container_footer{
        margin: 3rem 5rem !important;
    }
} */

