.input_search {
    font-family: "Prompt-Light";
    color: #939090 !important;
    /* border: 2px solid #939090 !important; */
    border: none !important;
    font-size: 16px;
    border-radius: 100px !important;

    font-weight: 600 !important;
    padding:  10px;


}

.current_location:hover{
    background-color: #808080a0 !important;
}

.input_search:focus{
    box-shadow: none !important;
}




.container_input_search{
    border: 2px solid #939090 !important;
    border-radius: 100px !important;
    width: 300px !important;
}


.tmp_button {
    font-family: "Prompt-Light";
    font-weight: normal !important;
    background-color: #121214 !important;
    border-radius: 100px !important;
    border: 2px solid #000000 !important;
    width: 180px;
    font-size: 16px;
}



.tmp_button:hover , .tmp_button:focus , .tmp_button:active{
    background-color: #000000 !important;
    border-radius: 100px !important;
    border-color: #000000 !important;
}


@media (max-width: 576px) {
    .tmp_button {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

}

