.container_verify{
    padding: 3rem 13rem;
}

.font_Medium {
    font-family: "Prompt-Medium" !important;
    font-weight: 500;
}

.font_Light {
    font-family: "Prompt-Light" !important;
    font-weight:500;
}


.tmp_input {
    border: 1px solid #939090 !important;
}

.tmp_select {
    border-color: #939090 !important;
}


.line{
    margin-top:  30px;
    margin-bottom: 30px;
    width:  100%;
    max-width: 100%;
}

.button_upload {
    font-family: "Prompt-Medium" ;
    font-weight: normal !important;
    border-radius:5px !important;
    width: 300px;
    font-size: 16px;
}



.button_upload:hover , .button_upload:focus , .button_upload:active{
    border-radius: 4px !important;
    border-color: #939090 !important;
}


.uploadImage{
    height: 350px;
    width: 100%;
    border: 2px dashed #939090;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tipsBox{
    height: 200px;
    width: 94%;
    border: 1px solid #939090;
    border-radius: 8px;
    padding : 14px 20px
}


.tmp_button {
    font-family: "Prompt-Light";
    font-weight: normal !important;
    background-color: #121214 !important;
    border-radius: 8px !important;
    border: 2px solid #000000 !important;
    width: 180px;
    height: 40px;
    font-size: 16px;
  }


  .tmp_button:hover , .tmp_button:focus , .tmp_button:active{
    background-color: #000000 !important;
    border-radius: 8px !important;
    border-color: #000000 !important;
  }


@media (min-width: 700px) and (max-width: 1280px) {
    .container_verify{
        padding: 5rem 10rem;
    }
}

@media (max-width: 576px) {
    .container_verify{
        padding: 2rem 1rem;
    }

    .button_upload {
        font-size: 13px !important;
        width: 170px;
    }

    .uploadImage{
        height: 200px;
    }

    .line{
        margin-top:  5px;
        margin-bottom: 5px;
    }
}


