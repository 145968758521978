.container_searchCar{
    padding: 4rem 0rem;
}

.tmp_input {
    border: 1px solid #939090 !important;
}

.tmp_select {
    border-color: #939090 !important;
}

.container_fluid{
    padding: 2rem 6rem;
}

.container{
    padding: 2rem 6rem;
}

.line{
    margin-top:  30px;
    margin-bottom: 30px;
    width:  100%;
    max-width: 100%;
}

.button_upload {
    font-family: "Prompt-Medium" ;
    font-weight: normal !important;
    border-radius:5px !important;
    width: 300px;
    font-size: 16px;
}



.button_upload:hover , .button_upload:focus , .button_upload:active{
    border-radius: 4px !important;
    border-color: #939090 !important;
}


.uploadImage{
    height: 350px;
    width: 100%;
    border: 2px dashed #939090;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tipsBox{
    height: 200px;
    width: 100%;
    border: 1px solid #939090;
    border-radius: 8px;
    padding : 14px 20px
}


.tmp_button {
    font-family: "Prompt-Light";
    font-weight: normal !important;
    background-color: #121214 !important;
    border-radius: 0px !important;
    border: 2px solid #000000 !important;
    width: 180px !important;
    font-size: 16px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0rem;
  }


.tmp_button:hover , .tmp_button:focus , .tmp_button:active{
    background-color: #000000 !important;
    border-radius: 0px !important;
    border-color: #000000 !important;
}

.border_line{
    border-bottom: 3px solid #27B1FF;
    font-family: "Prompt-Medium";
}

.border_time{
    border: 1px solid #939090;
    border-radius: 50px;

}

.box_message{
    border: 1px solid #939090;
    max-width:400px;
    border-radius: 5px;
}

.profile_read{
    position: relative;
    top: 10px;
}





.input_inbox{
    border: 1px solid #FDFDFD;
    border-radius: 10px;
    padding: 10px;
    background-color: rgb(227, 227, 227);
    width: 100%;
}

.input_search {
    font-family: "Prompt-Light";
    color: #939090 !important;
    /* border: 2px solid #939090 !important; */
    border: none !important;
    font-size: 16px;
    border-radius: 20px !important;
    font-weight: 600 !important;
}

.input_search:focus{
    box-shadow: none !important;
}

.border_card {

    border: 0.5px solid #DADADA ;
    border-radius: 10px;
}

.cardReview{
    border: 0.5px solid #DADADA ;
    border-radius: 10px;
    padding: 10px 20px;
}

.containerReview{
    margin-top: 10px;
    max-width: 100%;
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 0rem;
}

@media (min-width: 700px) and (max-width: 1280px) {
    .container_inbox{
        padding: 5rem 10rem;
    }
}

@media (max-width: 576px) {
    .container_inbox{
        padding: 2rem 1rem;
    }

    .container_fluid{
        padding: 0rem 0rem !important;
    }

    .container{
        padding: 2rem 1rem;
    }

    .button_upload {
        font-size: 13px !important;
        width: 170px;
    }

    .uploadImage{
        height: 200px;
    }

    .line{

        margin-top:  1px;
        margin-bottom: 1px;
    }


    .banner {
        height:  17vh;
        object-fit: center;
    }
}


