body {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

@font-face {
  font-family: Prompt-Bold;
  src: url("../src/frontoffice/assets/fonts/Prompt/Prompt-Bold.ttf");
}


@font-face {
  font-family: Prompt-Light;
  src: url("../src/frontoffice/assets/fonts/Prompt/Prompt-Light.ttf");
}

@font-face {
  font-family: Prompt-Medium;
  src: url("../src/frontoffice/assets/fonts/Prompt/Prompt-Medium.ttf");
}

.font_Medium {
  font-family: "Prompt-Medium" !important;
  font-weight: 500;
}

.font_Light {
  font-family: "Prompt-Light" !important;
  font-weight:500;
}


.nav-tabs .nav-link{
  border: 0px !important;
}


.gm-ui-hover-effect{
  position: relative;
  right: 10px !important;
  top: 10px !important;
  background-color: black !important;
  border-radius:  100px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  width : 30px !important;
  height : 30px !important;
}

.gm-ui-hover-effect>span{
  position: relative;
  width : 30px !important;
  height : 30px !important;
  padding : 10px !important;
  right:  2.5px !important;
  top:  0.5px !important;
  /* right : 50px !important */
  background-color: white !important;
}

.gm-style .gm-style-iw-c{
  max-width: 280px !important;
}

.markMap{
  position: relative;
  background-color: #fff;
  color: #000;
  padding: 10px 30px !important;
  height: 15px !important;
  border-radius :30px !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.markMap:hover{
  background-color: #000 !important;
  color: #fff !important;
}

.cursor_pointer{
  cursor: pointer;
}


.label_content{
  position:relative;
  border-radius: 5px;
  padding:5px;
  color:#ffffff;
  background-color: red;
  font-size: 20px;
  width: 100%;
  line-height: 20px;
  text-align: center;
  }

  .label_content:after {
  content:'';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-top: solid 10px red;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  }


.dropdown-toggle::after{
  display: none !important;
}

.dropdown-item, .dropdown-menu{
  background-color: white !important;
  color: #000 !important;
}


.dropdown-item, .dropdown-menu:active{
  color: #000 !important;
}


.menuBar {
  padding: 0 6rem;
  border-bottom: solid 1px #e8e8e8;
  overflow: auto;
  box-shadow: 0 0 30px #f3f1f1;
}

.logo {
  width: 150px;
  float: left;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
  text-transform: capitalize;
}

.menuCon {
  width: calc(100% - 150px);
  float: left;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .leftMenu {
  float: left;
}

.menuCon .rightMenu {
  float: right;
  width: 50%;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
  border: 1px solid #000;
}

.barsBtn {
  display: block;
  width: 30px;
  height: 2px;
  background: #000;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 30px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #000;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu>span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item,
.ant-drawer-body .ant-menu-horizontal>.ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item:hover {
  border-bottom-color: transparent;
}

#basic-navbar-nav{
  display: flex;
  align-items: center;
}

.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #3e8e41;}


@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }

  .menuBar {
    padding: 0 1rem;

  }


  #basic-navbar-nav{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -20px;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo a {
    padding: 10px 20px;
  }
}
